// extracted by mini-css-extract-plugin
export var bracket = "quickstart-module--bracket--bbe84";
export var fieldset = "quickstart-module--fieldset--ac37a";
export var gatsbyNoscript = "quickstart-module--gatsby-noscript--fe524";
export var gatsbyRespImageLink = "quickstart-module--gatsby-resp-image-link--92bdf";
export var help = "quickstart-module--help--90bc2";
export var input = "quickstart-module--input--57062";
export var label = "quickstart-module--label--16faf";
export var legend = "quickstart-module--legend--cfdc0";
export var option = "quickstart-module--option--9f391";
export var root = "quickstart-module--root--f15fe";
export var title = "quickstart-module--title--3e20b";