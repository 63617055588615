// extracted by mini-css-extract-plugin
export var dropdown = "sidebar-module--dropdown--cd852";
export var dropdownSelect = "sidebar-module--dropdown-select--b048c";
export var gatsbyNoscript = "sidebar-module--gatsby-noscript--c737a";
export var gatsbyRespImageLink = "sidebar-module--gatsby-resp-image-link--b08d8";
export var isActive = "sidebar-module--is-active--22c98";
export var label = "sidebar-module--label--ce9a8";
export var link = "sidebar-module--link--706d1";
export var logo = "sidebar-module--logo--50d9d";
export var root = "sidebar-module--root--adaff";
export var section = "sidebar-module--section--c6c66";